<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t("home.contacte") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3072.4330506776096!2d3.0029759152326996!3d39.63996757946291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1297c92e13208fa7%3A0x85f45410e61a909!2sToni%20Costa%20Ferro%20i%20Alumini!5e0!3m2!1ses!2ses!4v1663525483605!5m2!1ses!2ses"
          width="100%"
          height="400"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet class="d-flex justify-space-around mt-8 mb-10">
          <v-btn fab href="tel:+34971520813" color="#3E441D" dark> <v-icon color="FFF" >mdi-phone</v-icon></v-btn>
          <v-btn fab href="mailto:info@tonicostaferroialumini.com" target="_blank" color="#3E441D" dark><v-icon >mdi-email</v-icon></v-btn>
          <v-btn fab href="https://wa.me/34648292281" target="_blank" color="#3E441D" dark><v-icon >mdi-whatsapp</v-icon></v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Contacte",
};
</script>